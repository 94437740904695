<template>
  <div class="page-content" ref="container">
    <vc-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      @update:from-page="onPageChange"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-row flex-grow overflow-y-auto overflow-x-auto">
            <div v-for="attr in attributes" :key="attr.key">
              <p
                class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 cursor-pointer"
                :class="attr.customData.class"
                @click="onClickShowDetail(attr.customData.schedule)">
                {{ attr.customData.title }}
              </p>
              <br/>
            </div>
          </div>
        </div>
      </template>
    </vc-calendar>
    <schedule-info :show-modal="show_modal_info" :schedule="schedule_detail" @onClose="handlerCloseDetail"/>
  </div>
</template>
<script>
import moment from 'moment';
import notifApi from '../../api/notifications';
import ScheduleInfo from '../components/notifications/ScheduleInfo.vue';

export default {
  name: 'NotificationsSchedule',
  components: {
    ScheduleInfo,
  },
  metaInfo: {
    title: 'Schedules',
  },
  data() {
    return {
      masks: {
        weekdays: 'WWW',
      },
      show_modal_info: false,
      schedule_detail: {},
      attributes: [],
      query: {
        start_date: moment().startOf('month').format(''),
        end_date: moment().endOf('month').format(''),
      },
      loaderStack: 0,
      loader: null,
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
      availableChannel: {
        whatsapp: {
          name: 'WhatsApp',
          icon: 'mdi-whatsapp',
          color: 'text-success',
        },
        sms: {
          name: 'SMS',
          icon: 'mdi-mailbox',
          color: 'text-secondary',
        },
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    // this.getList();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getList() {
      this.showLoader();
      const { data: response } = await notifApi.getSchedule(this.activeWorkspace._id, this.query);
      this.attributes = response.rows.map((v, i) => {
        let badge = 'badge-warning';
        v.scheduled_at_str = moment(v.scheduled_at).format('DD MMM YYYY, HH:mm');
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        v.status_str = 'Pending';
        if (v.template_language) {
          v.flag_icon = this.relation[v.template_language.split('_')[0]];
          if (!v.flag_icon) {
            v.flag_icon = v.template_language.split('_')[0];
          }
        }
        if (v.schedule_executed_status === 'success') {
          v.status_str = 'Successfully executed';
          badge = 'badge-success';
        } else if (v.schedule_executed_status === 'failed') {
          v.status_str = 'Failed';
          badge = 'badge-danger';
        } else if (v.schedule_executed_status === 'cancelled') {
          v.status_str = 'Cancelled';
          badge = 'badge-secondary';
        }

        const currentChannel = this.availableChannel[v.channel_name];
        v.channel_icon = `mdi ${currentChannel.icon}`;
        if (currentChannel) {
          v.channel_name_str = currentChannel.name;
          v.channel_color = currentChannel.color;
        }
        v.class = `badge ${badge}`;
        return {
          key: i,
          customData: {
            title: `${moment(v.scheduled_at).format('HH:mm')} ${v.batch_name}`,
            class: `badge ${badge}`,
            schedule: v,
          },
          dates: moment(v.scheduled_at).format(),
        };
      });
      this.hideLoader();
    },
    onPageChange(value) {
      const start_value = moment().month(value.month - 1).year(value.year).startOf('month')
        .format();
      const end_value = moment().month(value.month - 1).year(value.year).endOf('month')
        .format();
      this.query = {
        start_date: start_value,
        end_date: end_value,
      };
      this.getList();
    },
    onClickShowDetail(schedule) {
      this.show_modal_info = true;
      this.schedule_detail = schedule;
    },
    handlerCloseDetail() {
      this.show_modal_info = false;
      this.schedule_detail = {};
    },
  },
};
</script>
<style lang="css">
.in-next-month {
  display: none !important;
}
.vc-container {
  font-family: 'JTMarnie';
}
</style>
<style lang="scss" scoped>
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.h-full {
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 125px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
